import { Map, List } from 'immutable'
import appUrl, { searchUrl } from '@libs/appUrl'
import getHashTagRegex from '@features/post/libs/getHashTagRegex'
import { ARTICLE_PREVIEW_NAME } from '@features/post/article-preview'

// old site Color
export const noImg =
  'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8++7DfwAJZwPMPd+20AAAAABJRU5ErkJggg=='

// redesign app no img color
export const redesignNoImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAKSURBVAjXY3gGAADoAOeNVZ+8AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAzLTE2VDA2OjU2OjAzKzAxOjAwLgU83AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMy0xNlQwNjo1NjowMyswMTowMF9YhGAAAAAASUVORK5CYII='

// post fetch Title And Description
export const isType = (input, detect = '') => {
  const post = Map.isMap(input) ? input.toJS() : input
  return post && detect && post.type === detect
}

export const getEditorType = (post) => post.web?.editorType || post.editorType

export const getPostTitle = (post) => post.web?.title || post.title

export const getPostOgTitle = (post) => post.web?.ogTitle || post.ogTitle

export const getPostOgDescription = (post) => post.web?.ogDescription || post.ogDescription

export const getPostOgImagePath = (post) => post.web?.ogImg?.path || post.ogImg?.path

export const getPostDescription = (post) => {
  if (post.newText) return parserNewTextToHtml(post.newText)

  if (post.web) return convertWebContent(post.web.text)

  return `<p>${convertContent(post.text)}</p>`
}

export const getTags = (post) => post.tags || []

// clas
export const hasClas = (clas = [], whiteList = []) => {
  const clasJS = List.isList(clas) ? clas.toJS() : clas
  let result = false
  whiteList.forEach((allow) => (result = result || clasJS.includes(allow)))
  return result
}

// FIXME: 是不是匿名聊判斷一次就好了，零零散散一直 call 到底是何苦呢？
// clas detect
export const isSecretClas = (clas = []) => hasClas(clas, ['secret'])
export const isSecretOrSexClas = (clas = []) => hasClas(clas, ['secret']) || hasClas(clas, ['sex'])

// number [1,000] as [1K]
export const convertNumToStr = (num = 0, min = 10000) => {
  if (num <= 0) return 0
  if (num < min || num < 1000) return num
  const prefix = ['k', 'M', 'G', 'T', 'P']
  const pow = Math.floor(Math.log(num) / Math.log(1000))
  return (num / Math.pow(1000, pow)).toFixed() + prefix[pow - 1]
}

// text content
export const parserToContext = (html, substr = null) => {
  const result = html
    .replace(/<[^>]+>/g, '')
    .replace(/\r\n/g, '')
    .replace(/\n/g, '')
  return result.substr(0, substr || result.length)
}

export const parserNewTextToHtml = (newText = []) =>
  newText
    .map((nt) => {
      const { type, data } = nt

      switch (type) {
        case 'header': {
          const headerLevel = Math.max(Math.min(parseInt(data.level, 10), 6), 1)
          return `<h${headerLevel}>${data.text}</h${headerLevel}>`
        }
        case 'image': {
          const { url, w, h } = data.file || data
          if (!url) return ''
          const alt = data.caption || ''
          const widthAttribute = w ? `width="${w}"` : ''
          const heightAttribute = h ? `height="${h}"` : ''

          return `<img src="${url}" alt="${alt}" ${widthAttribute} ${heightAttribute} />`
        }
        case 'embed': {
          if (data.service === ARTICLE_PREVIEW_NAME) {
            return `<p>${data.source}</p>`
          }

          return `<iframe src="${data.embed}" width="${data.width}" height="${data.height}"></iframe>`
        }
        case 'list': {
          if (!data.items?.length) return ''

          const tagName = data.style.toLowerCase() === 'ordered' ? 'ol' : 'ul'
          const innerHTML = data.items.map((item) => `<li>${item}</li>`).join('')
          return `<${tagName}>${innerHTML}</${tagName}>`
        }
        case 'delimiter': {
          return '<div class="delimiter" role="separator"><span></span><span></span><span></span></div>'
        }
        case 'paragraph': {
          if (data.text === '') return '<br>'
          return `<p>${data.text}</p>`
        }
        case 'quote': {
          if (!data.text) return '<br>'
          return `<div class="quote">${data.text.replace(/\n/g, '<br>')}</div>`
        }
        default: {
          return ''
        }
      }
    })
    .join('')

// text content replace
export const urlToLink = (input) => {
  const imgUrl = appUrl.imageBaseUrl

  let replacedText = input.replace(/&#8230;/gim, '...')

  // URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  replacedText = replacedText.replace(
    replacePattern1,
    '<a href="$1" target="_blank" rel="noopener">$1</a>',
  )

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="https://$2" target="_blank" rel="noopener">$2</a>',
  )

  // Change email addresses to mailto:: links.
  // const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  // Upload img
  const replacePattern4 = /(u\/){1}(.*)(\.jpg)/gim
  replacedText = replacedText.replace(replacePattern4, `<img alt src="${imgUrl}/$1$2$3" />`)
  // HashTag
  const replacePattern5 = getHashTagRegex()
  replacedText = replacedText.replace(replacePattern5, (...matches) => {
    const prefix = matches[1]
    const hashTag = matches[2] + matches[3]
    const href = searchUrl(hashTag)
    return `${prefix}<a href="${href}">${hashTag}</a>`
  })

  return replacedText
}

// text content convert to link
export const convertContent = (text = '') =>
  text
    .replace(/\r\n/g, '<br />')
    .replace(/\n/g, '<br />')
    .replace(
      /https:\/\/www\.popdaily\.com\.tw\/wp-content\/uploads\//g,
      'https://static.popdaily.com.tw/wp-content/uploads/',
    )

// web text content convert to link
export const convertWebContent = (text = '') =>
  text
    .replace(/https:\/\/static\.popdaily\.com\.tw\/article/g, 'https://www.popdaily.com.tw/article')
    .replace(
      /https:\/\/www\.popdaily\.com\.tw\/wp-content\/uploads\//g,
      'https://static.popdaily.com.tw/wp-content/uploads/',
    )

// comments list/like list convert to list and support topComment
export const convertComments = (list = [], likes = [], topComment = -1) => {
  // merge like list
  const likeCheck = likes.map((likeReply) => likeReply.replyID) //.filter((e, i, self) => self.indexOf(e) === i)
  likeCheck.forEach((replyID) => {
    const index = list.findIndex((reply) => reply.replyID == replyID)
    if (list[index]) list[index].isLike = true
  })

  // render new comments
  const newComments = []
  const mainComments = list.reverse().filter((reply) => reply.b === 0)
  const commentReply = list.filter((reply) => reply.b !== 0) // .reverse() or not
  // console.log(comments.length, mainComments.length, commentReply.length)

  const replyCheck = commentReply
    .map((reply) => reply.b)
    .filter((e, i, self) => self.indexOf(e) === i)
  replyCheck.forEach((e) => {
    const index = mainComments.findIndex((reply) => reply.replyID == e)
    if (mainComments[index]) mainComments[index].hasReply = true
  })

  // search and move to top - start
  if (topComment > -1) {
    let topReply = null
    const topCommentReplyIndex = commentReply.findIndex((reply) => reply.replyID === topComment)
    if (topCommentReplyIndex > -1) topReply = commentReply.splice(topCommentReplyIndex, 1)[0]

    const topMainCommentIndex = mainComments.findIndex((reply) => reply.replyID === topComment)
    if (topMainCommentIndex > -1) topReply = mainComments.splice(topMainCommentIndex, 1)[0]

    topReply && newComments.push(topReply)
  }
  // search and move to top - end

  const topTotal = mainComments.length
  for (let i = 0; i < topTotal; i++) {
    const mainReply = mainComments.shift()
    newComments.push(mainReply)

    const searchReplyRules = (reply) => reply.b === mainReply.replyID

    if (mainReply.hasReply) {
      const searchResultReply = commentReply.filter(searchReplyRules).reverse()
      const resultTotal = searchResultReply.length

      for (let j = 0; j < resultTotal; j++) {
        const reply = searchResultReply.shift()
        newComments.push(reply)
      }
    }
  }
  // Find diff comments for debug use, don't remove.
  // Array.prototype.diff = function(a) {
  //   return this.filter((i) => { return a.indexOf(i) < 0 })
  // }
  // console.log(comments.diff(newComments))
  return newComments
}

export const getEditorUrl = (post) => {
  const editorType = post.get('editorType')
  const articleId = post.get('id') || post.get('_id')
  const postID = post.get('postID')
  // 由新/舊編輯器發的文，重新編輯時會分別開啟新/舊編輯器
  return editorType === 'creator-v2' ? `/articles/${articleId}` : `/edit-post/${postID}`
}
