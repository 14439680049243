import React from 'react'
import PopImage from '@components/PopImage'
import ChevronRightIcon from '@components/Icons/ChevronRightIcon'
import styled from 'styled-components'
import tw, { theme } from 'twin.macro'
import { useSelector } from 'react-redux'
import { trackArticleContentEnterLink } from '@libs/eventTracking/article'

export const IMAGE_LINK_CARD_NAME = 'image-link-card'

const StylePopImage = styled(PopImage)`
  width: 100%;
  padding-top: ${(props) => props.ratio * 100}%;
`
const CardContainer = styled.a`
  margin-bottom: 12px;
  @media (min-width: ${theme`screens.md`}) {
    margin-bottom: 16px;
  }
`

const CardFooterContainer = styled.div`
  ${tw`text-primary-indigo text-h6 bg-primary-indigo font-semibold`}
  height: 40px;
  padding: 0 12px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${theme`screens.md`}) {
    padding: 0 16px;
    height: 44px;
    ${tw`text-article`};
  }

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
`

const CardText = styled.div`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  word-wrap: break-word;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  width: 16px;
  height: 16px;
  @media (min-width: ${theme`screens.md`}) {
    width: 24px;
    height: 24px;
  }
`

interface ImageLinkCardProps {
  text: string
  url: string
  noShaper: boolean
  index: number
  imageInfo: {
    alt?: string
    src: string
    width: string
    height: string
  }
}

const ImageLinkCard = (props: ImageLinkCardProps) => {
  const {
    text,
    url,
    noShaper,
    index,
    imageInfo: { alt = '', src, width, height },
  } = props
  if (!src) return null
  const ratio = width && height ? Math.floor((parseInt(height) / parseInt(width)) * 100) / 100 : 1
  const article = useSelector((state: any) => state.post.getIn(['post', 'data']).toJS())

  const handleCardOnClick = (e) => {
    e.preventDefault()
    trackArticleContentEnterLink({
      article,
      buttonText: text,
      linkType: 'imageCtaButton',
      toPageUrl: url,
    })
    window.open(url)
  }

  return (
    <CardContainer href={url} onClick={handleCardOnClick}>
      <StylePopImage
        showOriginal={noShaper}
        imageUrl={src}
        sizes={`(min-width: 1216px) 750px, (min-width: 992px) 620px, (min-width: 768px) 690px, calc(100vw - 30px)`}
        ratio={ratio}
        key={index}
        alt={alt}
      />
      <CardFooterContainer>
        <CardText>{text}</CardText>
        <StyledChevronRightIcon />
      </CardFooterContainer>
    </CardContainer>
  )
}

export default ImageLinkCard
