import qs from 'qs'

export const removeUrlSearchParams = (value: string) => {
  const url = new URL(value, 'http://localhost')
  for (const [key] of url.searchParams.entries()) {
    url.searchParams.delete(key)
  }
  return url.toString()
}

export const removeAllUrlSearchParams = (value: string) => {
  const url = new URL(value, 'http://localhost')
  return url.origin + url.pathname
}

export const appendUrlSearchParams = (url: string, params: any) => {
  const searchParams = qs.stringify(params, { allowDots: true })
  if (url.includes('?')) return url + '&' + searchParams
  return url + '?' + searchParams
}

export const getUrlSearchParams = (location: Location) => {
  return qs.parse(location.search.replace('?', ''), { allowDots: true })
}
