import { publicUrl } from '@libs/appUrl'
import { useAmp } from 'next/amp'
import { styled, css, theme } from 'twin.macro'
import { useCleanPostData } from './contexts/CleanPostDataContext'

export const COMMODITY_LINK_NAME = 'commodity-link'

const singleStyle = css`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;

  height: 120px;
  @media (min-width: ${theme`screens.md`}) {
    height: 160px;
  }

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  }
`

type ContainerProps = {
  isSingle?: boolean
}

const Container = styled.div<ContainerProps>`
  margin: 10px 0;
  /**
   * hack for iOS 11 and iOS 12 iframe width overflow
   * https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari
   */

  .${COMMODITY_LINK_NAME} {
    // 加重權限
    width: 0;
    min-width: 100%;
  }

  iframe {
    ${(props) => props.isSingle && singleStyle}
  }

  amp-iframe iframe {
    margin: 0;
  }
`

type Props = {
  products: string[]
}

export const CommodityLinkIframe = (props: Props) => {
  const isAmp = useAmp()
  const { products } = props
  const {
    post: { postID },
  } = useCleanPostData()

  const singleHeight = 120

  if (isAmp) {
    const children = products.map((product, index) => {
      const src = publicUrl(
        `/embed/commodityLink?postID=${postID}&p=${encodeURIComponent(product)}`,
      )

      return (
        <Container key={index} isSingle>
          <amp-iframe
            className={COMMODITY_LINK_NAME}
            src={src}
            height={singleHeight}
            layout="fixed-height"
            scrolling="no"
            sandbox="allow-top-navigation allow-top-navigation-by-user-activation"
          />
        </Container>
      )
    })

    // FIXME: 我也不知道為什麼
    // 一定要包起來 type check 才會過
    return <>{children}</>
  }

  // 為了縮短網址 products 縮寫成 p
  const src =
    publicUrl(`/embed/commodityLink?postID=${postID}`) +
    products.map((product) => '&p=' + encodeURIComponent(product)).join('')

  const isSingle = products.length === 1
  const height = isSingle ? singleHeight : 327

  return (
    <Container isSingle={isSingle}>
      <iframe
        className={COMMODITY_LINK_NAME}
        src={src}
        width="100%"
        height={height}
        scrolling="no"
      />
    </Container>
  )
}
