import { htmlparser2 } from 'react-html-parser'
import { urlToLink } from '@libs/postTools'
import getHashTagRegex from './getHashTagRegex'
import appUrl, { popSelectUrl, shaperUrl } from '@libs/appUrl'
import unescape from 'lodash/unescape'
import getInstagramRegex from './getInstagramUrlRegex'
import { COMMODITY_LINK_NAME } from '../commodity-link'
import { ARTICLE_PREVIEW_NAME } from '../article-preview'
import { IMAGE_LINK_CARD_NAME } from '../ImageLinkCard'

const isInstagram = (node) => {
  const testUrl = (url) => getInstagramRegex().test(url)

  if (node.type === 'text') return testUrl(node.data)

  const { name, attribs } = node

  if (name === 'blockquote' && testUrl(attribs['data-instgrm-permalink'])) return true

  if (name === 'iframe' && testUrl(attribs.src)) return true

  return false
}

export const removeCircularStructure = (nodes) => {
  nodes.forEach((node) => {
    delete node.next
    delete node.prev
    delete node.parent

    if (!node.children) return

    removeCircularStructure(node.children)
  })
}

const replaceParagraphAncestors = (node) => {
  if (!node) return

  const { type, name } = node

  if (type === 'tag' && name === 'p') {
    node.name = 'div'
  }

  replaceParagraphAncestors(node.parent)
}

const deleteNodePrevImage = (node) => {
  if (!node) return
  const { type, name } = node.prev

  if (type === 'tag' && name === 'img') {
    node.prev.name = 'nothing'
  }
}

export const preprocessNodes = (nodes, categoryIds) => {
  const helper = (nodes) => {
    /**
     * MAX: 不用 node.forEach 是因為新增、刪除節點
     * 用 node.filter 與 node.flatmap 效能比較差
     * 乾脆用最原始的 for loop 搞定
     */

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]

      switch (node.type) {
        case 'script':
        case 'comment': {
          nodes.splice(i, 1)
          i--
          break
        }
        case 'tag': {
          if (node.attribs.src) {
            node.attribs.src = unescape(node.attribs.src)
          }
          /**
           * MAX:
           *   .wp-caption 會自帶 style="width: 610px" 導致排版壞掉
           *   長期開發下來這個情況很常發生
           *   以後應以白名單的方式（只允許規定好的屬性）開發會比較不容易出錯
           */
          delete node.attribs.style

          if (isInstagram(node)) {
            const { 'data-instgrm-permalink': permalink, src, ...otherAttribs } = node.attribs
            const [url, shortCode] = getInstagramRegex().exec(permalink || src)

            replaceParagraphAncestors(node.parent)

            nodes.splice(i, 1, {
              ...node,
              name: 'instagram',
              attribs: { ...otherAttribs, url, shortCode },
              children: [],
            })
          }

          /**
           * h1 轉譯:
           *   編輯寫做 h1 但實際輸出是 h2
           *   for us good.
           *                     Willie
           */
          if (node.name === 'h1') node.name = 'h2'

          /**
           *  Wordpress 編輯器的分隔線會產生 <hr>
           *  前台文章頁要輸出成不同的分隔線結構和樣式
           *  結構同 https://github.com/popdaily/popdaily.server/blob/develop/web/src/libs/postTools.js#L97
           */
          if (node.name === 'hr') {
            nodes.splice(i, 1, {
              ...node,
              type: 'tag',
              name: 'div',
              attribs: { class: 'delimiter', role: 'separator' },
              children: [
                { type: 'tag', name: 'span', attribs: {}, children: [] },
                { type: 'tag', name: 'span', attribs: {}, children: [] },
                { type: 'tag', name: 'span', attribs: {}, children: [] },
              ],
            })
          }

          helper(node.children)
          break
        }
        case 'text': {
          if (node.parent?.name === 'a') break

          const articlePreviewUrlRegex = new RegExp(
            `((.*popdaily\\.com\\.tw)\\/)(forum\\/)?(${categoryIds.join('|')})\\/\\d+`,
          )

          const ImageLinkCardRegex = new RegExp(`^\{.+\}$`)
          /**
           * 編輯器跟 wordpress 的 Node 不一樣
           * 這邊分開邏輯去找圖片附加連結
           */
          // 編輯器
          const isEditorImageLinkCard =
            ImageLinkCardRegex.test(node.data) && node.parent?.prev?.name === 'img'
          // wordpress
          const isWpImageLinkCard =
            ImageLinkCardRegex.test(node.data) &&
            node.parent?.prev?.prev?.children &&
            node.parent?.prev?.prev?.children[0]?.name === 'img'
          const popSelectHomeUrl = popSelectUrl('/')

          if (articlePreviewUrlRegex.test(node.data)) {
            const [, origin] = node.data.match(articlePreviewUrlRegex)
            const postId = node.data.replace(origin, '').replace(/\//g, '.')
            const sourceUrl = node.data

            nodes.splice(i, 1, {
              type: 'tag',
              name: ARTICLE_PREVIEW_NAME,
              attribs: { postId, sourceUrl },
              children: [],
            })
          } else if (node.data.startsWith(popSelectHomeUrl)) {
            // 編輯器可能貼上的網址 https://popselect.popdaily.com.tw/me/M8ZmxZ/products/bluebird005
            const products: string[] = node.data.split(',').map((url: string) => {
              const productsMatched = url.match(/\/products\/([^/]+)/)
              const meMatched = url.match(/\/me\/([^/]+)/)

              if (!productsMatched) return ''
              if (!meMatched) return productsMatched[1]

              return `${meMatched[1]}/${productsMatched[1]}` // 分潤 / 產品
            })

            replaceParagraphAncestors(node.parent)

            nodes.splice(i, 1, {
              type: 'tag',
              name: COMMODITY_LINK_NAME,
              attribs: { products },
              children: [],
            })
          } else if (isEditorImageLinkCard) {
            // 編輯器在圖片後貼上 {敘述文字, 圖片網址}，把圖片變成連結卡片
            const [text, url] = node.data
              .replace(/\{|\}/g, '')
              .split(',')
              .map((s) => s.trim())

            replaceParagraphAncestors(node.parent)

            // 刪除原本的圖片
            node.parent.prev.name = 'nothing'

            nodes.splice(i, 1, {
              type: 'tag',
              name: IMAGE_LINK_CARD_NAME,
              attribs: {
                text,
                url,
                imageInfo: node.parent.prev.attribs,
              },
              children: [],
            })
          } else if (isWpImageLinkCard) {
            // wordpress 編輯器在圖片後貼上 {敘述文字, 圖片網址}，把圖片變成連結卡片
            const [text, url] = node.data
              .replace(/\{|\}/g, '')
              .split(',')
              .map((s) => s.trim())

            replaceParagraphAncestors(node.parent)

            // 刪除原本的圖片
            node.parent.prev.prev.children[0].name = 'nothing'

            nodes.splice(i, 1, {
              type: 'tag',
              name: IMAGE_LINK_CARD_NAME,
              attribs: {
                text,
                url,
                imageInfo: node.parent.prev.prev.children[0].attribs,
              },
              children: [],
            })
          } else if (isInstagram(node)) {
            // 將 IG 網址轉換成 IG 內嵌
            const [url, shortCode] = getInstagramRegex().exec(node.data)

            replaceParagraphAncestors(node.parent)

            nodes.splice(i, 1, {
              type: 'tag',
              name: 'instagram',
              attribs: { url, shortCode },
              children: [],
            })
          } else {
            nodes.splice(i, 1, ...htmlparser2.parseDOM(urlToLink(node.data)))
          }
          break
        }
        default:
          break
      }
    }
  }

  helper(nodes)
}

export const getPCount = (nodes) => {
  return nodes.reduce((result, node) => {
    if (node.type !== 'tag') return result

    return result + getPCount(node.children) + (node.name === 'p' ? 1 : 0)
  }, 0)
}

type Slot =
  | 'always'
  | 'between_1'
  | 'between_2'
  | 'between_3'
  | 'between_4'
  | 'between_5'
  | 'between_6'
  | 'between_7'
  | 'between_8'
  | 'between_9'
  | 'between_10'
  | 'between_n'

export type ADNode = {
  type: 'tag'
  name: 'ad'
  attribs: { slot: Slot }
  children: []
}

const createADNode = (slot: Slot): ADNode => ({
  type: 'tag',
  name: 'ad',
  attribs: { slot },
  children: [],
})

const getAD = (pIndex, pLastIndex): ADNode | void => {
  // 未滿 8 個 p ，則在最後一個 p 補 always
  if (pIndex == pLastIndex && pLastIndex < 8) return createADNode('always')
  if ((pIndex + 1) % 8 != 0) return

  const slotOrder = (pIndex + 1) / 8
  const slot = 'between_' + (slotOrder > 10 ? 'n' : slotOrder)

  return createADNode(slot as Slot)
}

export const appendADNodes = (nodes) => {
  const pCount = getPCount(nodes)
  let pIndex = 0
  const pLastIndex = pCount - 1

  const helper = (nodes) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (node.type !== 'tag') continue
      if (node.name === 'p') {
        const AD = getAD(pIndex++, pLastIndex)
        if (AD) {
          nodes.splice(i + 1, 0, AD)
          i++
        }
      }
      helper(node.children)
    }
  }

  helper(nodes)
}

export const getHashTags = (textContent) =>
  (textContent.match(getHashTagRegex()) || []).map((hashTag) =>
    hashTag.replace(/.*[#＃](.*)/, '$1'),
  )

export const appendTableOfContents = (nodes) => {
  let root = undefined
  let insertIndex = undefined
  const tableOfContents = {
    type: 'tag',
    name: 'table-of-contents',
    attribs: { items: [] },
    children: [],
  }
  let autoIncrement = 0

  const helper = (nodes) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (node.type !== 'tag') continue
      if (node.name !== 'h2' && node.name !== 'h3') {
        helper(node.children)
      } else {
        const textContent = getTextContent(node.children)
        if (!textContent) continue

        if (!root) {
          root = nodes
          insertIndex = i
        }
        node.attribs.id = node.name + '-' + autoIncrement++

        tableOfContents.attribs.items.push({
          type: node.name,
          href: '#' + node.attribs.id,
          textContent,
        })
      }
    }
  }

  helper(nodes)

  if (tableOfContents.attribs.items.length < 3) return
  root.splice(insertIndex, 0, tableOfContents)
}

const getTextContent = (nodes) => {
  let textContent = ''

  const helper = (nodes) => {
    nodes.forEach((node) => {
      if (node.type == 'tag') return helper(node.children)

      if (node.type == 'text') textContent += node.data
    })
  }

  helper(nodes)
  return textContent.trim()
}

export const getAllImages = (nodes) => {
  const results = []

  const helper = (nodes) => {
    nodes.forEach((node) => {
      if (node.type !== 'tag') return
      if (node.name !== 'img') return helper(node.children)

      results.push({
        src: node.attribs.src,
        width: node.attribs.width,
        height: node.attribs.height,
      })
    })
  }

  helper(nodes)
  return results
}

export const getCoverImage = (post) => {
  // TODO: 此為治標解法，之後不在此做 null check，預期 input 包含正確內容
  const { path, w, h } = post?.img?.[0] ?? {}
  if (path && w && h) {
    return {
      url: shaperUrl(path)({ 'resize-w': 800 }),
      width: 800,
      height: (800 / w) * h,
      path,
    }
  }

  return {
    url: appUrl.fbShareDefaultUrl,
    width: 700,
    height: 369,
    path,
  }
}
