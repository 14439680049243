import { css } from 'styled-components'
import { theme } from 'twin.macro'
import { appendUrlSearchParams, removeAllUrlSearchParams } from '@libs/url-utils'
import React, { useEffect, useState } from 'react'
import PopLink from '@components/PopLink'
import { useAmp } from 'next/amp'
import { publicUrl } from '@libs/appUrl'
import { trackArticlePreviewLinks } from '@libs/eventTracking/article/trackArticlePreviewLinks'
import { useCleanPostData } from '../contexts/CleanPostDataContext'

export const ARTICLE_PREVIEW_NAME = 'article-preview'

type Props = {
  sourceUrl: string
  postId: string
}

export function ArticlePreviewIframe(props: Props) {
  const { sourceUrl, postId } = props
  const { post } = useCleanPostData()
  const embedUrl = publicUrl(`/embed/article/${postId}`)

  const [targetLink, setTargetLink] = useState(sourceUrl)
  const isAmp = useAmp()

  const trackClickEvent = () => {
    trackArticlePreviewLinks(post, sourceUrl, postId)
  }

  useEffect(() => {
    setTargetLink(
      appendUrlSearchParams(sourceUrl, {
        fromPage: {
          pageName: 'article',
          pageComponent: 'content',
          pageUrl: removeAllUrlSearchParams(window.location.href),
        },
      }),
    )
  }, [sourceUrl])

  return (
    <PopLink
      className={ARTICLE_PREVIEW_NAME}
      shouldOpenInCurrentTab={true}
      url={targetLink}
      onClick={trackClickEvent}
    >
      {isAmp ? (
        <amp-iframe src={embedUrl} height="120" layout="fixed-height" scrolling="no" />
      ) : (
        <iframe src={embedUrl} height="160" width="100%" scrolling="no" />
      )}
    </PopLink>
  )
}

export const buildArticlePreviewEmbedPlugin = (categoryIds) => {
  const pattern = new RegExp(
    `(.*popdaily\\.com\\.tw)\\/((forum\\/)?(${categoryIds.join('|')})\\/\\d+)`,
  )
  return {
    name: ARTICLE_PREVIEW_NAME,
    config: {
      regex: pattern,
      id: ([origin, postId]) => `${origin}/embed/article/${postId.replace(/\//g, '.')}`,
      embedUrl: '<%= remote_id %>',
      html: `<iframe class="${ARTICLE_PREVIEW_NAME}" height="160" scrolling="no" frameborder="no" style="width: 100%;"></iframe>`,
      height: 160,
      width: 600,
    },
  }
}

const defaultStyle = css`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow: hidden;

  pointer-events: none;
  height: 120px;

  @media (min-width: ${theme`screens.md`}) {
    height: 160px;
  }
`

const hoverStyle = css`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
`

export const ArticlePreviewIframeStyle = css`
  .${ARTICLE_PREVIEW_NAME} {
    display: block;
    margin: 0;

    &:hover iframe {
      ${hoverStyle}
    }

    iframe {
      ${defaultStyle}
    }
  }
`

export const ArticlePreviewEditorIframeStyle = css`
  .${ARTICLE_PREVIEW_NAME} {
    ${defaultStyle}
  }
`
