import React from 'react'
import { withRNApp } from '@libs/RNApp'
import { useSelector } from 'react-redux'
import { PageName } from '@app-core/eventTracking/PageName'
import { trackArticleHashtagEnterSearchEvent } from '@libs/eventTracking/article/trackArticleHashtagEvent'
import { getHashTags } from '../features/post/libs/postTools'
import { appendUrlSearchParams } from '@libs/url-utils'

function PopLink(props) {
  const post = useSelector((state) => state.post.getIn(['post', 'data']))
  const { url, children, className, shouldOpenInCurrentTab, disableOnClick } = props

  const onClick = (e) => {
    if (disableOnClick) {
      e.preventDefault()
      return
    }

    const RNApp = window.RNApp
    if (RNApp) e.preventDefault()

    if (props.onClick) props.onClick(e)

    // this logic is copied from https://github.com/popdaily/popdaily.server/blob/master/web/views/assets/js/global.js#L540:L540
    if (
      url.indexOf('popdaily.com.tw') <= 18 &&
      (url.indexOf('/following') >= 0 ||
        url.indexOf('/forum') >= 0 ||
        url.indexOf('/user') >= 0 ||
        url.indexOf('/saved') >= 0 ||
        url.indexOf('/log') >= 0 ||
        url.indexOf('/notifications') >= 0 ||
        url.indexOf('/explore') >= 0 ||
        url.indexOf('/featured') >= 0 ||
        url.indexOf('/beauty') >= 0 ||
        url.indexOf('/style') >= 0 ||
        url.indexOf('/travel') >= 0 ||
        url.indexOf('/food') >= 0 ||
        url.indexOf('/japan') >= 0 ||
        url.indexOf('/korea') >= 0 ||
        url.indexOf('/life') >= 0 ||
        url.indexOf('/love') >= 0 ||
        url.indexOf('/mama') >= 0 ||
        url.indexOf('/press') >= 0 ||
        url.indexOf('/appevent') >= 0 ||
        url.indexOf('/event') >= 0 ||
        url.indexOf('/trend') >= 0 ||
        url.indexOf('/topic') >= 0 ||
        url.indexOf('/popbox') >= 0 ||
        url.indexOf('/place') >= 0 ||
        url.indexOf('?inapp') >= 0 ||
        url.indexOf('&inapp') >= 0 ||
        url.indexOf('2018gift') >= 0) &&
      url.indexOf('/category') < 0 &&
      url.indexOf('/article') < 0
    ) {
      if (RNApp && RNApp.webview2) {
        RNApp.webview2(url)
      } else if (RNApp) {
        alert('這個..麻煩更新版本， TestFlight 要重弄喔')
        window.open(url)
      }
    } else if (url.indexOf('popdaily.com.tw') <= 18 && url.indexOf('/policy') >= 0) {
      if (RNApp && RNApp.webview) {
        RNApp.webview(url)
      }
    } else if (url.indexOf('popdaily.com.tw') <= 18 && url.indexOf('/search') >= 0) {
      const fullHashtag = e.target.textContent
      const hashtag = fullHashtag ? getHashTags(fullHashtag)[0] : null
      if (hashtag) {
        trackArticleHashtagEnterSearchEvent({
          article: post.toJS(),
          toPageUrl: url,
          hashtag,
        })
      }

      if (RNApp && RNApp.webview3) {
        RNApp.webview3(url)
      } else if (RNApp && RNApp.webview2) {
        RNApp.webview2(url)
      } else {
        // hashtag 的行爲是原頁跳轉
        window.location.href = appendUrlSearchParams(url, {
          fromPage: {
            pageName: PageName.ARTICLE,
          },
        })
      }
    } else if (
      // app內文章是文章網址轉app用
      url.indexOf('popdaily.com.tw') <= 18 &&
      url.indexOf('/article') >= 0
    ) {
      if (RNApp && RNApp.webview2) {
        RNApp.webview2(url)
      }
    } else if (url.indexOf('/') == 0) {
      if (RNApp && RNApp.webview2) {
        RNApp.webview2(url)
      }
    } else if (RNApp && RNApp.openBrowser) {
      RNApp.openBrowser(url)
    } else if (RNApp) {
      alert('這個..麻煩更新版本， TestFlight 要重弄喔')
      window.open(url)
    }

    return false
  }

  const target = shouldOpenInCurrentTab ? undefined : '_blank'

  return (
    <a href={url} target={target} rel="noreferrer" onClick={onClick} className={className}>
      {children}
    </a>
  )
}

export default withRNApp(PopLink)
