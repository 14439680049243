import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getPostTitle,
  getPostDescription,
  getPostOgTitle,
  getPostOgDescription,
  getPostOgImagePath,
  getEditorType,
  getTags,
} from '@libs/postTools'

import { postUrl } from '@libs/appUrl'
import mapKeys from 'lodash/mapKeys'
import upperFirst from 'lodash/upperFirst'
import getDisplayName from '@hocs/getDisplayName'
import { imageUrl } from '@libs/appUrl'

type Value = {
  post: any
  title: string
  description: string
  nodes: any
  plainTextContent: string
  hashTags: string[]
  url: string
  ogTitle: string
  ogDescription: string
  ogImagePath: string
  editorType: string
  userProfile: {
    link: string
    avatarUrl: string
    name: string
  }
  location?: {
    name: string
    id: string
  }
  isFollowingAuthor: boolean
  tags?: string[]
}

const context = createContext<Value>(null)
const { Provider, Consumer } = context

type CleanPostDataProviderProps = {
  nodes: any
  children: ReactNode
}

export function CleanPostDataProvider(props: CleanPostDataProviderProps) {
  const { nodes, children } = props
  const immutablePost = useSelector<any, any>((state) => state.post.getIn(['post', 'data']))
  const value = useMemo(() => {
    const post = immutablePost.toJS()
    const title = getPostTitle(post)
    const ogTitle = getPostOgTitle(post)
    const ogDescription = getPostOgDescription(post)
    const description = getPostDescription(post)
    const ogImagePath = getPostOgImagePath(post)
    const tags = getTags(post)
    const url = postUrl(post.postID)
    const editorType = getEditorType(post)
    const noShaper = post.categories?.includes(28) || post?.userNote?.includes('廣編文章')

    // 文章為發佈狀態時，加上發佈時間
    if (post.ptype === 1) post.publishedTime = post.t

    const userProfile = {
      link: `/user/${post.userInfo.mid}`,
      avatarUrl: post.userInfo.img ? imageUrl(post.userInfo.img) : '/statics/images/head2.png',
      name: post.userInfo.name,
    }

    const location =
      post.loc && post.locName
        ? {
            name: post.locName,
            id: post.loc,
          }
        : null

    const isFollowingAuthor = Boolean(post.isFollowPostUser)

    return {
      post,
      title,
      description,
      plainTextContent: post.plainTextContent || '',
      hashTags: post.hashTags || [],
      url,
      ogTitle,
      ogDescription,
      ogImagePath,
      editorType,
      noShaper,
      userProfile,
      location,
      isFollowingAuthor,
      tags,
      nodes,
    }
  }, [immutablePost])

  return <Provider value={value}>{children}</Provider>
}

export const withCleanPostData = (prefix: string) => (Component) => {
  function WithCleanPostData(props) {
    return (
      <Consumer>
        {(value) => {
          const valueWithPrefix = prefix
            ? mapKeys(value, (_value, key) => prefix + upperFirst(key))
            : value
          return <Component {...valueWithPrefix} {...props} />
        }}
      </Consumer>
    )
  }
  WithCleanPostData.displayName = `withCleanPostData(${getDisplayName(Component)})`

  return WithCleanPostData
}

export const useCleanPostData = () => useContext(context)
