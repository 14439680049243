import React, { ImgHTMLAttributes, HtmlHTMLAttributes } from 'react'
import { shaperUrl } from '@libs/appUrl'
import { omitBy, isNil } from 'lodash/fp'
import styled from 'styled-components'

interface PopImageProps {
  imageUrl: ImgHTMLAttributes<HTMLImageElement>['src']
  sizes?: ImgHTMLAttributes<HTMLImageElement>['sizes']
  alt?: ImgHTMLAttributes<HTMLImageElement>['sizes']
  ratio?: number
  showOriginal?: boolean
  noShaper?: boolean
  className?: string
}

interface GenSrcSetArgs {
  imageUrl: string
  ratio?: number
}

const genSrcset = ({ imageUrl, ratio }: GenSrcSetArgs) => {
  let srcSet = ''
  for (let i = 100; i <= 2100; i += 400) {
    const shapeOptions: any = omitBy(isNil)({
      'resize-w': i,
      'resize-h': ratio ? Math.floor(i * ratio) : null,
    })
    srcSet += `${shaperUrl(imageUrl)(shapeOptions)} ${i}w${i === 2100 ? '' : ', '}`
  }
  // Generate WebP srcset
  let webpSrcSet = ''
  for (let i = 100; i <= 2100; i += 400) {
    const shapeOptions: any = omitBy(isNil)({
      'resize-w': i,
      'resize-h': ratio ? Math.floor(i * ratio) : null,
      format: 'webp',
    })
    webpSrcSet += `${shaperUrl(imageUrl)(shapeOptions)} ${i}w${i === 2100 ? '' : ', '}`
  }

  // Combine JPEG and WebP srcsets
  srcSet = `${webpSrcSet}, ${srcSet}`

  return srcSet
}

const FullWidthImageContainer = styled.span`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`

/**
 * 裡面的 image 是依據 parent 的寬高延伸，所以 parent 需定義寬高度，且順帶防止 CLS
 */
const PopImage = (props: PopImageProps & HtmlHTMLAttributes<HTMLSpanElement>) => {
  const { imageUrl, sizes, ratio, alt = '', showOriginal, className, noShaper } = props

  const lowQualityShapeOptions: any = omitBy(isNil)({
    'resize-w': 100,
    'resize-h': ratio ? Math.floor(100 * ratio) : null,
    quality: 1,
  })
  const srcset = genSrcset({ imageUrl, ratio })
  const lowQualityImageUrl = shaperUrl(imageUrl)(lowQualityShapeOptions)

  // 這個 component 不使用 react 的寫法去處理 onload，因為 hydration 速度太慢，改使用 javascript 方式處理
  return (
    <FullWidthImageContainer
      className={className}
      dangerouslySetInnerHTML={{
        __html: noShaper
          ? `
        <img  
          alt="${alt}"
          src="${imageUrl}"
          style="width: 100%; height: 100%; margin: 0; padding: 0; position: absolute; top: 0; left: 0;"
        />
        `
          : `
        <img  
          alt="${alt}"
          src="${lowQualityImageUrl}"
          style="width: 100%; height: 100%; margin: 0; padding: 0; position: absolute; top: 0; left: 0;display: block;visibility: visible; transition: visibility 0.1s ease-in-out; filter: blur(1.0rem);"
        />
        <img
          alt="${alt}"
          src="${imageUrl}"
          srcset="${showOriginal ? '' : srcset}"
          sizes="${showOriginal ? '' : sizes}"
          style="width: 100%; height: 100%; margin: 0; padding: 0;position: absolute; top: 0; left: 0;display: block;opacity: 0;transition: opacity 0.1s ease-in-out;"
          onload="this.previousElementSibling.style.visibility = 'hidden'; this.style.opacity = 100;"
        />
        <noscript><img src="${imageUrl}" alt="${alt}" width="1" height="1" /></noscript>
        `,
      }}
    />
  )
}

export default PopImage
